import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, Link, Typography } from '@material-ui/core';
import { FormTextField } from '../../../../App/Shared/Form/FormTextField';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from '../../../../Context/Context';
import { StepsTypes } from '../../../../Context/StepsReducer';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormCheckbox } from '../../../../App/Shared/Form/FormCheckbox';
import { ShortRegistrationSchema } from './schema';
import { DropFilesField } from '../../../../App/Shared/Form/DropFiled/DropFilesField';
import { ShortRegModel } from './interface';
import {
  getAxiosWithUploadConfig,
  getRegistrationData,
  putShortRegistration,
  uploadFiles,
} from '../../../Services/SignUpServices';
import { ShortRegData } from '../../../../Types/RegDataPut';
import { useParams } from 'react-router-dom';
import { Help } from '../../../../App/Shared/Help/Help';
import { FileRejection } from 'react-dropzone';

interface ShortRegistrationProps {
  showDetailForm: () => void;
}

const passwordTooltip =
  'Bitte verwenden Sie ein sicheres Passwort z.B.' +
  ' durch die Verwendung von Sonderzeichen, Groß- und Kleinschreibung und' +
  ' Ziffern. Die Mindestlänge sollte 8 Zeichen betragen.';

export const ShortRegistration: React.FC<ShortRegistrationProps> = ({
  showDetailForm,
}): JSX.Element => {
  const classes = StepFormStyles();
  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);
  const params = useParams<{ firstPart: string; secondPart: string }>();
  const { shortReg } = steps;
  const mdView = useMediaQuery('(max-width:1360px)');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm<ShortRegModel>({
    defaultValues: {
      firstName: shortReg.firstName,
      surname: shortReg.surname,
      email: shortReg.email,
      profileUrl: shortReg.profileUrl,
      uploadedGenDocs: shortReg.uploadedGenDocs,
      password: shortReg.password,
      passwordConfirmation: shortReg.passwordConfirmation,
      isGdpr: shortReg.isGdpr,
      isFreelancer: shortReg.isFreelancer,
    },
    resolver: yupResolver(ShortRegistrationSchema),
    mode: 'all',
  });

  //adding filled state to inputs
  useEffect(() => {
    const initialValFields = [
      'firstName',
      'surname',
      'email',
      'profileUrl',
      'uploadedGenDocs',
      'password',
      'passwordConfirmation',
      'isGdpr',
      'isFreelancer',
    ];

    const valFieldsWithFilledState = initialValFields.filter(
      name =>
        // @ts-ignore
        methods.getValues(name).length > 0,
    );

    // @ts-ignore
    methods.trigger(valFieldsWithFilledState);
  }, []);

  const saveFields = useCallback(() => {
    const stepFormFields = methods.getValues();
    const newFields = { ...shortReg, ...stepFormFields };

    dispatch({
      type: StepsTypes.SET_SHORT_REG_DATA,
      payload: {
        fields: {
          ...newFields,
        },
      },
    });
  }, [shortReg, dispatch]);

  const prepareData = (): ShortRegData => {
    const signup_link = `${params.firstPart}/${params.secondPart}`;
    const stepFormFields = methods.getValues();
    const newFields = { ...shortReg, ...stepFormFields };

    return {
      signup_link,
      modules: {
        'contact-api-short': {
          first_name: newFields.firstName,
          last_name: newFields.surname,
          email: newFields.email,
          website: newFields.profileUrl,
          password: newFields.password,
        },
      },
    };
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    const formData = prepareData();

    try {
      const response = await putShortRegistration(formData);
      if (shortReg.uploadedGenDocs.length > 0) {
        await uploadFiles(response.data.signup_id, shortReg.uploadedGenDocs);
      }

      dispatch({
        type: StepsTypes.SET_SIGNUP_ID,
        payload: {
          signupId: response.data.signup_id,
        },
      });
      setIsSubmitting(false);
      setIsSubmitted(true);
    } catch (e: any) {
      setIsSubmitting(false);
      handleFormSnackbar(e.message);
      console.log(e);
    }
    // setTimeout(() => {
    //   setIsSubmitting(false);
    //   setIsSubmitted(true);
    //   console.log('formData', formData);
    // }, 2000);
  };

  const handleFormSnackbar = useCallback(
    (message: string) => {
      dispatch({
        type: StepsTypes.SET_FORM_SNACKBAR,
        payload: {
          formSnackbar: true,
          formSnackbarMessage: message,
        },
      });
    },
    [dispatch],
  );

  const onDropGenDocs = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections?.[0]?.errors.some(f => f.code === 'file-invalid-type')) {
        handleFormSnackbar('Dateityp wird nicht unterstützt.');
      }
      if (acceptedFiles.length > 0) {
        const newFile = acceptedFiles[0];
        const dropFiles = methods.getValues('uploadedGenDocs');
        const newDropFiles = [...dropFiles, newFile];
        methods.setValue('uploadedGenDocs', newDropFiles);
        saveFields();
      }
    },
    [methods],
  );

  // const onDropProfile = useCallback(
  //   (acceptedFiles: File[]) => {
  //     methods.setValue('uploadedGenDocs', acceptedFiles[0]);
  //     saveFields();
  //   },
  //   [methods],
  // );

  // useEffect(() => {
  //   console.log('methods.formState.submitCount', methods.formState.submitCount);
  //   if (methods.formState.isSubmitted && !methods.formState.isValid) {
  //     if (
  //       Object.keys(methods.formState.errors).length === 1 &&
  //       !!methods.formState.errors.passwordConfirmation &&
  //       methods.formState.errors.passwordConfirmation.type === 'oneOf'
  //     ) {
  //       handleFormSnackbar('Bitte wiederhole das Passwort noch einmal korrekt.');
  //     } else {
  //       handleFormSnackbar('Bitte fülle alle Pflichtfelder aus.');
  //     }
  //   }
  // }, [methods.formState.submitCount]);

  const handleInvalid = () => {
    if (
      !!methods.formState.errors.passwordConfirmation &&
      methods.formState.errors.passwordConfirmation.type === 'oneOf'
    ) {
      handleFormSnackbar('Bitte wiederhole das Passwort noch einmal korrekt.');
    } else {
      handleFormSnackbar('Bitte fülle alle Pflichtfelder aus.');
    }
  };
  if (isSubmitted) {
    return (
      <div className={`${classes.form} ${classes.vertCenter}`}>
        <Grid className={classes.root} item container direction="column" md={!mdView ? 7 : 9}>
          <FormHeader label="Danke für Deine Basisinformationen!" />
          <Typography variant="body2" className={classes.header}>
            Damit wir mit passenden Projektanfragen auf Dich zukommen können, geht es jetzt weiter
            mit <strong>Deinen Fachbereichen, Deinen Stundensatzvorstellungen</strong> und{' '}
            <strong>weiteren Angaben</strong>.
          </Typography>
          <Typography variant="body2" className={classes.header}>
            Je mehr wir über Dich erfahren, desto besser können wir mit passenden Projektanfragen
            auf Dich zukommen.
          </Typography>
          <Grid
            item
            xs={12}
            container
            direction="column"
            spacing={1}
            className={classes.fieldsWrapper}>
            <Grid item xs={12}>
              <Button className={classes.nextBtn} type="button" onClick={showDetailForm}>
                Weiter
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      <Grid container item justifyContent="flex-end">
        <Help />
      </Grid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, handleInvalid)} className={classes.form}>
          <Grid className={classes.root} item container direction="column" md={!mdView ? 7 : 9}>
            <FormHeader label="Start" />
            <Typography variant={'body1'} className={classes.header}>
              Gib folgende Daten an, damit wir mit Projekten auf Dich zu kommen können.
            </Typography>
            <Grid
              item
              xs={12}
              container
              direction="column"
              spacing={3}
              className={classes.fieldsWrapper}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormTextField name="firstName" label="Vorname*" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField name="surname" label="Nachname*" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormTextField name="email" label="E-Mail-Adresse*" />
              </Grid>

              <Grid item xs={12}>
                <FormTextField
                  type="password"
                  name="password"
                  label="Passwort*"
                  tooltipTitle={passwordTooltip}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  type="password"
                  name="passwordConfirmation"
                  label="Passwort wiederholen*"
                />
              </Grid>

              <Grid item xs={12}>
                <FormTextField
                  name="profileUrl"
                  label="Webseite/LinkedIn/Xing URL*"
                  placeholder="Xing-/LinkedIn Profil oder Webseite"
                />
              </Grid>
              <Grid item xs={12}>
                <DropFilesField
                  onDrop={onDropGenDocs}
                  name="uploadedGenDocs"
                  accept=".pdf,.doc,.docx,.zip,.rar,.7z"
                  supportedFileNames=".pdf, .doc, .docx, .zip, .rar, .7z (max. 64 MB)"
                  hasError={!!methods.formState.errors?.uploadedGenDocs?.type}
                  label="CV / Portfolio"
                />
              </Grid>

              <Grid item xs={12}>
                <FormCheckbox
                  name={'isGdpr'}
                  label={
                    <>
                      {'Ich stimme der Verarbeitung meiner Daten laut '}
                      <Link
                        href="https://bizforward.de/datenschutzerklaerung/"
                        target={'_blank'}
                        variant="body2"
                        color={'secondary'}
                        style={{ fontSize: 16 }}>
                        Datenschutzerklärung
                      </Link>
                      {' zu.'}
                    </>
                  }
                />
                <FormCheckbox name="isFreelancer" label="Ich bin bereits selbstständig tätig" />
              </Grid>
            </Grid>
            <Button className={classes.nextBtn} type="submit" disabled={isSubmitting}>
              Abschicken
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
