import { Box, CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core';
import { AppContext } from './Context/Context';
import { FreelancerSignUpTheme } from './App/Theme/Theme';
import './Fonts/stylesheet.css';
import { Theme } from '@material-ui/core/styles';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TwoRowsLayout } from './Layout/TwoRowsLayout';
import { StepsLayout } from './Layout/StepsLayout';
import { checkLink, getRegistrationData } from './Domain/Services/SignUpServices';
import { StepsTypes } from './Context/StepsReducer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SuccessSubmit } from './Domain/components/LastTaps/SuccessSubmit';
import { FailedData } from './Domain/components/LastTaps/FailedData';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MobileStepsLayout } from './Layout/MobileStepsLayout';
import { MobileSuccessSubmit } from './Domain/components/LastTaps/MobileSuccessSubmit';
import { ShortRegistration } from './Domain/components/Steps/ShortRegistration';
import { MobileWrapper } from './Layout/MobileWrapper';

function App() {
  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);
  const { regData, step } = steps;
  const mobileView = useMediaQuery('(max-width:1024px)');
  const [shortRegistration, setShortRegistration] = useState<boolean>();

  const handleStep = useCallback(
    (value: number) => {
      dispatch({
        type: StepsTypes.SET_STEP,
        payload: {
          step: value,
        },
      });
    },
    [step],
  );

  const handleCompletedStep = useCallback(
    (value: number) => {
      dispatch({
        type: StepsTypes.SET_COMPLETED_STEP,
        payload: {
          completedStep: value,
        },
      });
    },
    [dispatch],
  );

  const handlePretendStep = useCallback(
    (pretendStepParam: number) => {
      dispatch({
        type: StepsTypes.SET_PRETEND_STEP,
        payload: {
          pretendStep: pretendStepParam,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    const params = window.location.pathname.split('/');
    const firstParam = params[1];
    const secondParam = params[2];
    const link = firstParam + '/' + secondParam;

    (async function () {
      try {
        const isValidLink = await checkLink(link);

        if (!isValidLink.data.success) {
          handleStep(0);
        } else {
          const response = await getRegistrationData();
          setShortRegistration(true);
          dispatch({
            type: StepsTypes.SET_REG_DATA,
            payload: {
              regData: response.data,
            },
          });
        }
      } catch {
        handleStep(0);
      }
    })();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      if (step > 0 && step < 9) {
        e.preventDefault();
        return (e.returnValue = '');
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <ThemeProvider theme={FreelancerSignUpTheme}>
      <CssBaseline />
      <Box style={{ position: 'relative' }}>
        <Router>
          <Routes>
            <Route
              path={'/'}
              element={
                <>
                  {mobileView && <FailedData mobile={true} />}
                  {!mobileView && (
                    <TwoRowsLayout
                      imgSrc={'/Images/warning.svg'}
                      smallImage
                      rightContent={<FailedData mobile={false} />}
                    />
                  )}
                </>
              }
            />
            <Route
              path={'/freelancer'}
              element={
                <>
                  {mobileView && <FailedData mobile={true} />}
                  {!mobileView && (
                    <TwoRowsLayout
                      imgSrc={'/Images/warning.svg'}
                      smallImage
                      rightContent={<FailedData mobile={false} />}
                    />
                  )}
                </>
              }
            />
            <Route
              path={'/:firstPart/:secondPart'}
              element={
                <>
                  {!mobileView && (
                    <>
                      {step === 0 && (
                        <TwoRowsLayout
                          imgSrc={'/Images/warning.svg'}
                          smallImage
                          rightContent={<FailedData mobile={false} />}
                        />
                      )}
                      {regData?.success && (
                        <>
                          {shortRegistration ? (
                            <TwoRowsLayout
                              imgSrc={'/Images/person.svg'}
                              rightContent={
                                <ShortRegistration
                                  showDetailForm={() => {
                                    setShortRegistration(false);
                                  }}
                                />
                              }
                            />
                          ) : (
                            <>
                              {step >= 1 && step < 6 && (
                                <StepsLayout
                                  handleStep={handleStep}
                                  step={step}
                                  handleCompletedStep={handleCompletedStep}
                                  handlePretendStep={handlePretendStep}
                                />
                              )}
                              {step === 6 && (
                                <TwoRowsLayout
                                  imgSrc={'/Images/rocket.svg'}
                                  rightContent={<SuccessSubmit />}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {mobileView && (
                    <>
                      {step === 0 && <FailedData mobile={true} />}
                      {regData?.success && (
                        <>
                          {shortRegistration ? (
                            <MobileWrapper>
                              <ShortRegistration
                                showDetailForm={() => {
                                  setShortRegistration(false);
                                }}
                              />
                            </MobileWrapper>
                          ) : (
                            <>
                              {regData?.success && step > 0 && step < 6 && (
                                <MobileStepsLayout
                                  handleStep={handleStep}
                                  step={step}
                                  handleCompletedStep={handleCompletedStep}
                                  handlePretendStep={handlePretendStep}
                                />
                              )}
                              {regData?.success && step === 6 && <MobileSuccessSubmit />}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              }
            />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
