import { Controller, useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { Radio, FormControlLabel, RadioGroup, makeStyles } from '@material-ui/core';
import { FormRadioStyles } from './Styles/FormRadioStyles';
import { Theme } from '@material-ui/core/styles';
import { LevelObj } from '../../../Types/FormFields';

interface FormRadioProps {
  name: string;
  radioOptions: string[] | LevelObj[];
}

export const FormRadioStyled = FormRadioStyles(Radio);

const useStyles = makeStyles<Theme>(theme => {
  return {
    radioGroup: {
      padding: '24px 0',
    },
    customLabel: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.primary.main,
      margin: 0,
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  };
});

export const FormGroupRadio = (props: FormRadioProps) => {
  const classes = useStyles();
  const { name, radioOptions } = props;
  const { control } = useFormContext();

  // Auswahl Grundkenntnisse
  // Erweiterte Kenntnisse
  // Sehr gute Kenntnisse
  // Expertenlevel

  const CustomRadioGroup = useMemo(() => {
    const checkedItem = radioOptions[0];
    const isOptionsString = typeof checkedItem === 'string';
    const radioOptionsString = isOptionsString && (radioOptions as string[]);
    const radioOptionsObj = !isOptionsString && (radioOptions as LevelObj[]);

    return isOptionsString ? (
      <>
        {radioOptionsString &&
          radioOptionsString.map((radioOption: string) => {
            return (
              <FormControlLabel
                key={radioOption}
                value={radioOption}
                control={<FormRadioStyled />}
                label={radioOption}
                className={classes.customLabel}
              />
            );
          })}
      </>
    ) : (
      <>
        {radioOptionsObj &&
          radioOptionsObj.map((radioOption: LevelObj) => {
            return (
              <FormControlLabel
                key={radioOption.id}
                value={radioOption.id}
                control={<FormRadioStyled />}
                label={radioOption.label}
                className={classes.customLabel}
              />
            );
          })}
      </>
    );
  }, [radioOptions, name]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <RadioGroup {...field} className={classes.radioGroup}>
              {CustomRadioGroup}
            </RadioGroup>
          );
        }}
      />
    </>
  );
};
