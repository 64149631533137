import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { AppContext } from '../../../../Context/Context';
import { StepsTypes } from '../../../../Context/StepsReducer';
import { CheckboxRadioSchema } from '../../../Schema/CheckboxRadioSchema';
import { SearchAndSuggestions } from './SearchAndSuggestions';
import { CheckboxListItemApi, GroupApi, RegDataTypes } from '../../../../Types/ResponseType';
import { CheckboxRadioListItem, GroupOpt } from '../../../../Types/FormFields';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import { CustomGroupModal } from './CustomGroupModal';
import { useFormSnackbar } from '../../../../Hooks/useFormSnackbar';
import { TechStackModel } from './interface';
import { SearchFilter } from '../../SearchFilter';
import { SearchTextField } from '../../../../App/Shared/Form/Customized/SearchTextField';
import { GroupSelection } from '../../GroupSelection';
import { GroupItemsSelection } from '../../GroupItemsSelection';
import clsx from 'clsx';

interface TechStackFormProps {
  handleStep: (value: number) => void;
  step: number;
  handlePretendStep: (value: number) => void;
  handleCompletedStep: (value: number) => void;
}

export interface SaveFieldsParams {
  groupLabel: string;
  label?: string;
  level?: string;
  isChecked?: boolean;
}

export interface Technologies {
  groupLabel: string;
  entries: TechnologiesEntries[];
}

export interface TechnologiesEntries {
  id: string;
  label: string;
  visible?: boolean;
  level?: string;
}

export interface HandleUpdateParams {
  parent: string;
  id: string;
  value: string | null;
  label?: string;
}

const DEFAULT_SEARCH_PLACEHOLDER = 'React';
export const TechStackForm = (props: TechStackFormProps): JSX.Element => {
  const classes = StepFormStyles();
  const { handleStep, step, handlePretendStep, handleCompletedStep } = props;
  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);
  const { fields, regData, pretendStep, completedStep } = steps;

  const { modules } = regData as RegDataTypes;
  const { technologies } = modules;
  const handleFormSnackbar = useFormSnackbar();

  // const groupsSwitchModals = technologies.map((group: GroupApi) => ({
  //   isOpen: false,
  //   label: group.groupLabel,
  // }));
  // const [switchModals, setSwitchModals] = useState(groupsSwitchModals);

  // const getSwitchModalIdx = (groupLabel: string) =>
  //   switchModals.findIndex(
  //     (switchModal: { label: string; isOpen: boolean }) => switchModal.label === groupLabel,
  //   );
  //
  // const handleSwitchModals = useCallback(
  //   (groupLabel: string, isOpen: boolean) => {
  //     saveFields();
  //     const switchModalIdx = getSwitchModalIdx(groupLabel);
  //
  //     const switchModal = switchModals[switchModalIdx];
  //     switchModal.isOpen = isOpen;
  //
  //     const newSwitchModals = switchModals;
  //     newSwitchModals[switchModalIdx] = switchModal;
  //
  //     setSwitchModals([...newSwitchModals]);
  //   },
  //   [switchModals],
  // );

  const getTechnologiesOpt = useCallback((techGroup: GroupApi) => {
    const techOptVisible = techGroup.entries.filter((tech: CheckboxListItemApi) => tech.visible);

    return techOptVisible.map((tech: CheckboxListItemApi) => ({
      label: tech.label,
      level: '',
      isChecked: false,
    }));
  }, []);

  const techsGroupsOpt: GroupOpt[] = technologies.map((techGroup: GroupApi) => {
    return { ...techGroup, entries: getTechnologiesOpt(techGroup) };
  });

  const methods = useForm<TechStackModel>({
    defaultValues: {
      techsGroupsOpt: fields.techsGroupsOpt.length ? fields.techsGroupsOpt : techsGroupsOpt,
      search: '',
      sugSearchActive: false,
      filteredCheckboxes: [],
    },
    resolver: yupResolver(
      CheckboxRadioSchema(
        'techsGroupsOpt',
        'at least one should be chosen with level',
        'groupsData',
      ),
    ),
    mode: 'all',
  });
  //
  // const watchFields = methods.watch();
  // // const currentSearchValue = methods.getValues('search');
  // // console.log('get tha shit', methods.getValues('techsGroupsOpt'));
  //
  // const saveFieldsX = useCallback(
  //   (params?: SaveFieldsParams) => {
  //     const stepFormFields = methods.getValues('techsGroupsOpt');
  //
  //     if (params?.label && params?.level && params?.isChecked !== undefined) {
  //       const groupIndex = stepFormFields.findIndex(
  //         group => group.groupLabel === params?.groupLabel,
  //       );
  //       const entryIndex = stepFormFields[groupIndex].entries.findIndex(
  //         entry => entry.label === params?.label,
  //       );
  //
  //       if (groupIndex >= 0 && entryIndex >= 0) {
  //         stepFormFields[groupIndex].entries[entryIndex].level = params?.level;
  //         stepFormFields[groupIndex].entries[entryIndex].isChecked = params?.isChecked;
  //       }
  //     }
  //     const newFields = { ...fields, techsGroupsOpt: stepFormFields };
  //
  //     console.log('stepFormFields', stepFormFields);
  //
  //     dispatch({
  //       type: StepsTypes.SET_FIELDS,
  //       payload: {
  //         fields: {
  //           ...newFields,
  //         },
  //       },
  //     });
  //   },
  //   [fields, dispatch],
  // );

  const onSubmitPrevious = (data: any) => {
    saveFields();
    const newStep = step - 1;
    handleStep(newStep);
  };

  const handlePreviousStep = () => {
    saveFields();
    methods.handleSubmit(onSubmitPrevious)();
  };

  const onSubmit = (data: any) => {
    const newStep = step + 1;

    saveFields();

    handleStep(newStep);

    if (completedStep < step) {
      handleCompletedStep(step);
    }
  };

  // const getCheckedCheckboxesQuantity = useCallback(() => {
  //   const techsGroupsOpt = methods.getValues('techsGroupsOpt');
  //   const techsOpt = techsGroupsOpt.map((group: GroupOpt) => group.entries).flat();
  //   const techsChecked = techsOpt.filter((tech: CheckboxRadioListItem) => tech.isChecked);
  //
  //   return techsChecked.length;
  // }, [methods]);

  useEffect(() => {
    const errorsL = Object.entries(methods.formState.errors).length;

    if (methods.formState.isSubmitted && errorsL > 0) {
      handleFormSnackbar(true, 'Bitte fülle die Tech-Stack Angaben komplett aus.');
    }
  }, [methods.formState.submitCount]);
  //
  // const getCustomGroupModalPlaceholder: { [key: string]: string } = {
  //   Programmiersprachen: 'Java',
  //   'Web Technologien': 'html',
  //   Frameworks: 'React',
  //   'Software und Tools': 'Sketch',
  // };

  const onSubmitOutside = (data: any) => {
    saveFields();
    handleStep(pretendStep);
  };

  useEffect(() => {
    if (pretendStep !== 0) {
      methods.handleSubmit(onSubmitOutside)();
      handlePretendStep(0);
    }
  }, [pretendStep]);

  // const saveData = () => {
  //   console.log('do tha save!');
  // };

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------

  // new
  const [selectedTechnologies, setSelectedTechnologies] = useState<Technologies[]>(technologies);
  const [currentGroup, setCurrentGroup] = useState('');
  const [groupItems, setGroupItems] = useState<GroupApi[]>([]);
  const [searchPlaceholder, setSearchPlaceholder] = useState(DEFAULT_SEARCH_PLACEHOLDER);
  const [searchValue, setSearchValue] = useState<string | undefined>('');

  const defaultGroups = selectedTechnologies.map(group => group.groupLabel);
  //technologies: [..]
  // const savedItems = [
  //   {
  //     groupLabel: 'Programmiersprachen',
  //     entries: [
  //       {
  //         label: 'ABAP',
  //         level: '1',
  //       },
  //       {
  //         label: 'C',
  //         level: '4',
  //       },
  //     ],
  //   },
  //   {
  //     groupLabel: 'Web Technologien',
  //     entries: [
  //       {
  //         label: 'AJAX',
  //         level: '3',
  //       },
  //     ],
  //   },
  // ];

  // console.log('fields.techsGroupsOpt', fields.techsGroupsOpt);
  // console.log('technologies', technologies);
  // console.log(methods.getValues('techsGroupsOpt'));

  const handleFilterSearch = (searchValue: string) => {
    if (searchValue === '') {
      setSearchValue('');
      if (currentGroup === '') {
        setGroupItems([]);
      }
      return;
    }
    const globalSearch = currentGroup === '';
    const groups = globalSearch ? selectedTechnologies : getItems(currentGroup);

    const filteredGroups = groups.map(groupItem => {
      return {
        groupLabel: groupItem.groupLabel,
        entries: groupItem.entries.filter(item =>
          globalSearch
            ? item?.label?.toLowerCase()?.startsWith(searchValue.toLowerCase())
            : item?.label?.toLowerCase()?.includes(searchValue.toLowerCase()),
        ),
      };
    });

    setGroupItems(filteredGroups);
    setSearchValue(searchValue);
  };

  const showGroupItems = (group: string) => {
    setCurrentGroup(group);
  };

  const closeSelection = () => {
    setCurrentGroup('');
    setSearchValue('');
  };

  const updateItems = ({ parent, id, value }: HandleUpdateParams) => {
    const techIndex = selectedTechnologies.findIndex(tech => tech.groupLabel === parent);

    if (techIndex > -1) {
      const itemIndex = selectedTechnologies[techIndex].entries.findIndex(item => item.id === id);
      const updatedTechnologies = structuredClone(selectedTechnologies);

      if (itemIndex > -1) {
        value
          ? (updatedTechnologies[techIndex].entries[itemIndex].level = value)
          : delete updatedTechnologies[techIndex].entries[itemIndex].level;
        setSelectedTechnologies(updatedTechnologies);
      } else {
        const newEntry = [
          {
            id: '',
            label: searchValue || '',
            visible: true,
            level: value || '',
          },
        ];
        updatedTechnologies[techIndex].entries = [
          ...newEntry,
          ...updatedTechnologies[techIndex].entries,
        ];
        setSelectedTechnologies(updatedTechnologies);
        setSearchValue('');
      }
    }
  };

  const getItems = (groupLabel: string) => {
    const selectedItems =
      selectedTechnologies.filter(group => group.groupLabel === groupLabel) || [];

    return selectedItems.map(items => ({
      groupLabel: items.groupLabel,
      entries: items.entries.filter(item => item.visible || item.level),
    }));
  };

  const getCount = (group: Technologies) => {
    return group.entries.filter(item => item.level).length;
  };

  const getTotal = () => {
    return selectedTechnologies.reduce(
      (partialsum, tech) => partialsum + tech.entries.filter(item => item.level).length,
      0,
    );
  };

  const saveFields = () => {
    const newFields = { ...fields, techsGroupsOpt: selectedTechnologies };

    dispatch({
      type: StepsTypes.SET_FIELDS,
      payload: {
        fields: {
          ...newFields,
        },
      },
    });
  };

  useEffect(() => {
    const storedValues = methods.getValues('techsGroupsOpt');
    const storedValuesWithChecks = storedValues.map(group => {
      if (group.entries.filter(entry => entry.level).length > 0) {
        return {
          groupLabel: group.groupLabel,
          entries: group.entries.filter(entry => entry.level),
        };
      }
      return null;
    });

    // setting initial technologies with saved value
    const updatedTechnologies = selectedTechnologies.map((group, ix) => {
      const savedItemGroup = storedValuesWithChecks.filter(
        item => item?.groupLabel === group.groupLabel,
      );

      let entries = group.entries;
      if (savedItemGroup.length > 0) {
        entries = entries.map(entry => {
          const savedItem =
            savedItemGroup?.[0]?.entries?.filter(savedItem => savedItem.label === entry.label) ||
            [];

          return {
            ...entry,
            ...(savedItem.length > 0 && {
              level: savedItem?.[0]?.level,
            }),
          };
        });
      }

      let newEntries: TechnologiesEntries[] = [];

      if (savedItemGroup) {
        newEntries =
          (savedItemGroup[0]?.entries.map(savedItem => {
            const hasNewItems =
              technologies[ix]?.entries?.filter(entry => entry.label === savedItem.label).length ===
              0;
            return hasNewItems ? savedItem : {};
          }) as TechnologiesEntries[]) || [];
      }

      return {
        groupLabel: group.groupLabel,
        entries: [...newEntries, ...entries],
        // entries,
      };
    });

    setSelectedTechnologies(updatedTechnologies);
  }, []);

  useEffect(() => {
    if (searchValue !== '') {
      handleFilterSearch(searchValue ?? '');
      return;
    }

    if (currentGroup !== '') {
      setGroupItems(getItems(currentGroup));
      return;
    }
    setSearchValue('');
    setGroupItems([]);
  }, [currentGroup, selectedTechnologies, searchValue]);

  useEffect(() => {
    // console.log('GROUP ITEMS', groupItems);
    if (groupItems.length === 0 || groupItems.length > 1) {
      setSearchPlaceholder(DEFAULT_SEARCH_PLACEHOLDER);
    } else {
      switch (groupItems[0].groupLabel) {
        case 'Programmiersprachen':
          setSearchPlaceholder('Java');
          break;
        case 'Web Technologien':
          setSearchPlaceholder('html');
          break;
        case 'Frameworks':
          setSearchPlaceholder('React');
          break;
        case 'Software und Tools':
          setSearchPlaceholder('Sketch');
          break;
        default:
          setSearchPlaceholder(DEFAULT_SEARCH_PLACEHOLDER);
          break;
      }
    }
  }, [groupItems]);

  return (
    <>
      <Grid className={classes.root} container item direction="column" lg={6} md={7}>
        <Grid container direction="column" className={classes.fieldsWrapper}>
          <FormHeader label="Tech-Stack" />
          <Typography variant="body2" className={classes.subHeader}>
            Wähle aus mit welchen Programmiersprachen, Technologien, Frameworks und Tools Du Dich
            gut auskennst. Du hast auch die Möglichkeit, noch Weitere über das Freitextfeld
            hinzuzufügen.
          </Typography>

          <SearchFilter
            placeholder={`z. B. „${searchPlaceholder}“`}
            filterHandleChange={handleFilterSearch}
            searchValue={searchValue}
          />
          <div className={classes.selectionWrapper}>
            <div className={clsx({ [classes.opacityDisabled]: searchValue })}>
              {selectedTechnologies.map((technology, ix) => (
                <GroupSelection
                  label={technology.groupLabel}
                  key={`${technology.groupLabel + ix}`}
                  onGroupSelection={showGroupItems}
                  selectionNumber={getCount(technology)}
                />
              ))}
            </div>

            <GroupItemsSelection
              groups={groupItems}
              closeSelection={closeSelection}
              updateItems={updateItems}
              searchValue={searchValue}
              defaultGroups={defaultGroups}
            />
          </div>
          <Button className={classes.nextBtn} onClick={onSubmit}>
            <Box className={classes.btnContentWrapper}>
              Weiter
              {getTotal() > 0 && <Box className={classes.checkedNumber}>{getTotal()}</Box>}
            </Box>
          </Button>
          <Button
            className={classes.backBtn}
            color={'primary'}
            variant={'outlined'}
            onClick={handlePreviousStep}>
            Zurück
          </Button>
        </Grid>
      </Grid>
      {/*<p>---- old stuff ----</p>*/}
      {/*<p>---- old stuff ----</p>*/}
      {/*<p>---- old stuff ----</p>*/}
      {/*<p>---- old stuff ----</p>*/}
      {/*<p>---- old stuff ----</p>*/}
      {/*<p>---- old stuff ----</p>*/}
      {/*<p>---- old stuff ----</p>*/}
      {/*<FormProvider {...methods}>*/}
      {/*  <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>*/}
      {/*    <Grid className={classes.root} container item direction={'column'} lg={6} md={7}>*/}
      {/*  <FormHeader label={'Tech-Stack'} />*/}
      {/*  <Typography variant="body2" className={classes.subHeader}>*/}
      {/*    Wähle aus mit welchen Programmiersprachen, Technologien, Frameworks und Tools Du Dich*/}
      {/*    gut auskennst. Du hast auch die Möglichkeit, noch Weitere über das Freitextfeld*/}
      {/*    hinzuzufügen.*/}
      {/*  </Typography>*/}
      {/*  <Grid container direction={'column'} className={classes.fieldsWrapper}>*/}
      {/*    <SearchAndSuggestions*/}
      {/*      handleSwitchModals={handleSwitchModals}*/}
      {/*      rootMethods={methods}*/}
      {/*      placeholder={'React'}*/}
      {/*    />*/}

      {/*    {methods.getValues('techsGroupsOpt').map((techsGroup: GroupOpt, idx: number) => {*/}
      {/*      const isOpen = switchModals[getSwitchModalIdx(techsGroup.groupLabel)].isOpen;*/}

      {/*      return (*/}
      {/*        <CustomGroupModal*/}
      {/*          key={techsGroup.groupLabel}*/}
      {/*          label={techsGroup.groupLabel}*/}
      {/*          name={`techsGroupsOpt[${idx}].entries`}*/}
      {/*          groupLabel={techsGroup.groupLabel}*/}
      {/*          groupLabelIdx={idx}*/}
      {/*          header={techsGroup.groupLabel}*/}
      {/*          isLastModal={idx + 1 === technologies.length}*/}
      {/*          handleSwitchModals={handleSwitchModals}*/}
      {/*          isOpen={isOpen}*/}
      {/*          placeholder={getCustomGroupModalPlaceholder[techsGroup.groupLabel]}*/}
      {/*          rootMethods={methods}*/}
      {/*          saveFields={saveFields}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </Grid>*/}
      {/*      <Button className={classes.nextBtn} type={'submit'}>*/}
      {/*        <Box className={classes.btnContentWrapper}>*/}
      {/*          Weiter*/}
      {/*          {getCheckedCheckboxesQuantity() > 0 && (*/}
      {/*            <Box className={classes.checkedNumber}>{getCheckedCheckboxesQuantity()}</Box>*/}
      {/*          )}*/}
      {/*        </Box>*/}
      {/*      </Button>*/}
      {/*      <Button*/}
      {/*        className={classes.backBtn}*/}
      {/*        color={'primary'}*/}
      {/*        variant={'outlined'}*/}
      {/*        onClick={handlePreviousStep}>*/}
      {/*        Zurück*/}
      {/*      </Button>*/}
      {/*    </Grid>*/}
      {/*  </form>*/}
      {/*</FormProvider>*/}
    </>
  );
};
