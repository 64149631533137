import { FormProvider, useForm } from 'react-hook-form';
import { Button, Divider, Grid, Link, Typography } from '@material-ui/core';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { FormTextField } from '../../../../App/Shared/Form/FormTextField';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from '../../../../Context/Context';
import { FieldsTypes, StepsTypes } from '../../../../Context/StepsReducer';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import { FormNumberField } from '../../../../App/Shared/Form/FormNumberField';
import { FormCheckbox } from '../../../../App/Shared/Form/FormCheckbox';
import { FormKeyboardDate } from '../../../../App/Shared/Form/FormKeyboardDate';
import { detailsSchema } from './schema';
import { useParams } from 'react-router-dom';
import { RegDataTypes } from '../../../../Types/ResponseType';
import { useFormSnackbar } from '../../../../Hooks/useFormSnackbar';
import { FormCheckboxModal } from '../../../../App/Shared/Form/Modal/FormCheckboxModal';
import { FormDropdown } from '../../../../App/Shared/Form/FormDropdown';
import { FormAutocomplete } from '../../../../App/Shared/Form/FormAutocomplete';
import { DetailsModel, DetailsModelFields } from './interface';
import { getModulesApi } from './helper';
import { addRegData } from '../../../Services/SignUpServices';

interface DetailsProps {
  handleStep: (value: number) => void;
  step: number;
  handlePretendStep: (value: number) => void;
}

export const DetailsForm = (props: DetailsProps): JSX.Element => {
  const classes = StepFormStyles();
  const { handleStep, step, handlePretendStep } = props;
  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);

  const { fields, regData, pretendStep, signupId } = steps;
  const { modules } = regData as RegDataTypes;
  const params = useParams<{ firstPart: string; secondPart: string }>();
  const handleFormSnackbar = useFormSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dailyRateError, setDailyRateError] = useState(false);
  const [daysPerWeekError, setDaysPerWeekError] = useState(false);

  const methods = useForm<DetailsModel>({
    defaultValues: {
      daily_rate: fields.daily_rate,
      available_from: fields.available_from,
      days_per_week: fields.days_per_week,
      postCode: fields.postCode,
      residence: fields.residence,
      country: fields.country,
      regions: fields.regions,
      areaCode: fields.areaCode,
      phone: fields.phone,
      comment: fields.comment,
      contract_staffing: fields.contract_staffing,
      newsletter: fields.newsletter,
      pro_bono: fields.pro_bono,
      insurance: fields.insurance,
    },
    resolver: yupResolver(detailsSchema),
    mode: 'all',
  });

  const currentDaysPerWeek = methods.watch('days_per_week');

  useEffect(() => {
    const initialValFields = ['daily_rate', 'comment'];
    // const initialValFields = ['comment'];

    const valFieldsWithFilledState = initialValFields.filter(
      name =>
        // @ts-ignore
        methods.getValues(name).length > 0,
    );

    // @ts-ignore
    methods.trigger(valFieldsWithFilledState);
  }, []);

  const saveFields = useCallback(() => {
    const stepFormFields = methods.getValues();
    const newFields = { ...fields, ...stepFormFields };

    dispatch({
      type: StepsTypes.SET_FIELDS,
      payload: {
        fields: {
          ...newFields,
        },
      },
    });
  }, [fields, dispatch]);

  const handlePreviousStep = useCallback(() => {
    const newStep = step - 1;

    saveFields();
    handleStep(newStep);
  }, [handleStep, step]);

  const possibleToValidate = (): boolean => {
    const dailyRate = methods.getValues('daily_rate');
    const daysPerWeek = methods.getValues('days_per_week');
    if (dailyRate && dailyRate < 149) {
      handleFormSnackbar(true, 'Bitte gebe einen Tagessatz an der höher als 149,- EUR ist.');
      return false;
    }
    if (daysPerWeek && daysPerWeek < 0) {
      handleFormSnackbar(true, 'Bitte gebe deine Arbeitstage pro Woche zwischen 1 und 5 an.');
      return false;
    }

    return true;
  };

  const getDataApi = (fields: FieldsTypes, finalStepData: DetailsModelFields) => {
    const contract_staffing = finalStepData.contract_staffing
      ? modules.contract_staffing.yes
      : modules.contract_staffing.no;

    const modulesPut = getModulesApi(fields, finalStepData, contract_staffing);

    return {
      signup_id: signupId,
      modules: modulesPut,
    };
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    if (!possibleToValidate()) {
      setIsSubmitting(false);
      return;
    }

    try {
      const currentData: DetailsModelFields = {
        ...data,
        daily_rate: methods.getValues('daily_rate'),
        days_per_week: methods.getValues('days_per_week'),
        postCode: methods.getValues('postCode'),
        phone: methods.getValues('phone'),
      };

      const newStep = step + 1;
      const apiData = getDataApi(steps.fields, currentData);

      // console.log('apiData', apiData);
      //
      // setTimeout(() => {
      //   console.log('apiData', apiData);
      //   setIsSubmitting(false);
      //   // handleStep(step + 1);
      // }, 2000);

      const response = await addRegData(apiData);
      if (response.data.success) {
        handleStep(newStep);
      } else {
        handleFormSnackbar(true, response.data.error);
      }
    } catch (e: any) {
      setIsSubmitting(false);
      handleFormSnackbar(true, e.message);
      console.log(e);
    }
  };

  useEffect(() => {
    if (methods.formState.isSubmitted && Object.keys(methods.formState.errors).length > 0) {
      if (
        Object.keys(methods.formState.errors).length === 1 &&
        methods.formState.errors.daily_rate
      ) {
        handleFormSnackbar(true, 'Bitte gebe einen Tagessatz an der höher als 149,- EUR ist.');
      } else {
        handleFormSnackbar(true, 'Bitte fülle alle Pflichtfelder aus.');
      }
    }
  }, [methods.formState.submitCount]);

  useEffect(() => {
    if (pretendStep !== 0) {
      saveFields();

      handlePretendStep(0);
      handleStep(pretendStep);
    }
  }, [pretendStep]);

  useEffect(() => {
    if (currentDaysPerWeek) {
      setDaysPerWeekError(!(currentDaysPerWeek > 0 && currentDaysPerWeek < 6));
    }
  }, [currentDaysPerWeek]);

  const handleDailyRateBlur = () => {
    const currentDailyRate = methods.getValues('daily_rate');
    if (currentDailyRate) {
      setDailyRateError(!(currentDailyRate > 149));
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
        <Grid className={classes.root} container item direction={'column'} lg={6} md={7}>
          <FormHeader label="Fast geschafft!" />
          <Typography variant={'body2'} className={classes.subHeader}>
            Hier kannst Du uns noch die letzten organisatorischen Informationen geben, die uns
            helfen, Dir die passenden Projekte vorzuschlagen.
          </Typography>
          <Grid container direction={'column'} spacing={3} className={classes.fieldsWrapper}>
            <Grid item>
              <FormTextField
                name={'daily_rate'}
                label="Tagessatz"
                tooltipTitle="Wie hoch ist in etwa Dein Tagessatz exklusive Reisekosten bei einem Projekt mit z. B. 3 Monaten Laufzeit und 4 Tagen Einsatz pro Woche?"
                hasError={dailyRateError}
                validateOnBlur={handleDailyRateBlur}
              />
            </Grid>
            <Grid item>
              <FormKeyboardDate
                name="available_from"
                label="Verfügbar ab"
                tooltipTitle="Ab wann bist Du voraussichtlich wieder offen für neue Projekte?"
              />
            </Grid>
            <Grid item>
              <FormNumberField
                hasError={daysPerWeekError}
                name={'days_per_week'}
                label={'Arbeitstage pro Woche'}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={5} sm={4}>
                  <FormTextField name={'postCode'} label={'Postleitzahl'} />
                </Grid>
                <Grid item xs={7} sm={8}>
                  <FormTextField name={'residence'} label={'Wohnort'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormDropdown name={'country'} label={'Land'} placeholder={'Land'} />
            </Grid>
            <Grid item>
              <FormCheckboxModal
                options={modules.regions}
                name="regions"
                label="Örtliche Flexibilität für Projekte"
                header="Örtliche Flexibilität für Projekte"
                subHeader={
                  'Bitte wähle in welchen Regionen Du Vor-Ort-Projekte betreuen kannst oder ob Du nur Remote arbeitest.\n'
                }
              />
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={5} sm={4}>
                  <FormAutocomplete name={'areaCode'} label={'Ländervorwahl'} />
                </Grid>
                <Grid item xs={7} sm={8}>
                  <FormTextField name={'phone'} label={'Telefonnummer'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormTextField name="comment" label="Bemerkung" multiline />
            </Grid>
            <Grid item>
              <FormCheckbox
                name={'insurance'}
                label={'Ich verfüge über eine Berufshaftpflichtversicherung'}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name={'contract_staffing'}
                label={'Ich bin offen für Projekte in ANÜ.'}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name={'pro_bono'}
                label={
                  'Ich bin grundsätzlich bereit einige Stunden oder Tage im Jahr bei gemeinnützigen Projekten im Rahmen der bizforward plus Initiative pro bono tätig zu sein.'
                }
                tooltipTitle={
                  'Mit bizforward plus möchten wir unsere Kompetenzen und unser Netzwerk nutzen, um gemeinnützige Organisationen zu unterstützen. Ein Fokus liegt dabei auf der\n' +
                  '       Vermittlung von Freelancern aus unserem Pool für fachliche Aufgaben, die diese Organisationen nicht alleine stemmen können.'
                }
              />
            </Grid>
            <Divider className={classes.divider} />
            <Grid item>
              <FormCheckbox
                name={'newsletter'}
                label={
                  'Ich möchte einige Male pro Jahr die bizforward News per E-Mail erhalten (Infos rund um das Freelancer-Business).\n'
                }
              />
            </Grid>
          </Grid>
          <Button className={classes.nextBtn} type={'submit'} disabled={isSubmitting}>
            Abschicken
          </Button>
          <Button
            className={classes.backBtn}
            color={'primary'}
            variant={'outlined'}
            onClick={handlePreviousStep}>
            Zurück
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
